import React from "react";
import '../helpers/suppress-install-prompt';
import Layout from "../components/Layout";
import Seo from "../components/seo";

import { Container, Row, Col, Button } from 'reactstrap';
import { GatsbyImage } from "gatsby-plugin-image";
import BackgroundImage from 'gatsby-background-image';
import { convertToBgImage } from "gbimage-bridge"
import scrollToElement from '../helpers/scrollToElement';

import { graphql } from "gatsby";

import sign from '../images/company/sign.svg';

import icon1 from '../images/company/icon_1_change.svg'
import icon2 from '../images/company/icon_2_training.svg'
import icon3 from '../images/company/icon_3_online_care.svg'
import icon4 from '../images/company/icon_4_special_meal.svg'
import icon5 from '../images/company/icon_5_stats.svg'
import icon6 from '../images/company/icon_6_motivation.svg'
import icon7 from '../images/company/icon_7_straining_stuff.svg'
import icon8 from '../images/company/icon_8_shopping.svg'

import balance from '../images/company/balance.png';
import appIcon from '../images/company/app_iconx2.png';

import jarek from '../images/company/jaroslaw.jpg';

const Page = ({ data }) => {
    const bgImage = convertToBgImage(data.hero.childImageSharp.gatsbyImageData)
    return(
    <Layout page="company" showMenu={true} ctaProductName="dostęp dla pracowników">
        <Seo
            title="Oferta dla firm: Skuteczne treningi oraz dieta w aplikacji"
            description="Zadbaj o swoich pracowników podczas #HomeOffice. Odbierz rabat dla firm!"
            ogImage={data.ogImage.childImageSharp.gatsbyImageData.src}
        />

        <BackgroundImage
            {...bgImage}
            Tag="section"
            className="hero"                
        >
            <Container>
                
                <h1 className="text-center">Zadbaj o swoich pracowników podczas #HomeOffice</h1>
                <h2 className="text-center">Szybkie i skuteczne treningi oraz dieta w aplikacji</h2>
                <Button onClick={() =>
                    scrollToElement(
                        document.getElementById('buy')
                    )
                }>
                    Odbierz rabat dla firm
                </Button>

                <Row>
                    <Col xs={{size: 6, offset: 6}} md={{size: 3, offset: 9}}>
                    <img src={sign} className="sign" alt="" />
                    </Col>
                </Row>
            </Container>
        </BackgroundImage>

        <section className="company1">
            <Container>
                <Row>
                    <Col md="6">
                        <GatsbyImage image={data.app1.childImageSharp.gatsbyImageData} />
                    </Col>
                    <Col md="6">
                        <ul>
                            <li>Ponad 40 planów treningowych</li>
                            <li>Różne poziomy trudności</li>
                            <li>Treningi już od 15 minut dziennie</li>
                            <li>Taniej, niż karnet na siłownię</li>
                            <li>Ćwiczenia bez sprzętu - zacznij trening od razu</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </section>

        <section className="company2">
            <Container>
                <h2 className="heading-2 text-center">Idealna <span className="bg">alternatywa dla siłowni i&nbsp;dietetyka,</span><br/>
                którą możesz zapewnić swoim pracownikom</h2>
            </Container>
        </section>

        <section className="company3">
            <Container>
                <Row>
                    <Col xs="6" md="3">
                        <img src={icon5} alt="" />
                        <p>Pomiary i śledzenie postępów</p>
                    </Col>
                    <Col xs="6" md="3">
                        <img src={icon6} alt="" />
                        <p>Rady, wskazówki, motywacja</p>
                    </Col>
                    <Col xs="6" md="3">
                        <img src={icon7} alt="" />
                        <p>Możliwości ćwiczenia ze sprzętem lub bez</p>
                    </Col>
                    <Col xs="6" md="3">
                        <img src={icon8} alt="" />
                        <p>Nagrania HD z&nbsp;ekspertami</p>
                    </Col>
                </Row>

                <Row className="mt-md-5">
                    <Col xs="6" md="3">
                        <img src={icon1} alt="" />
                        <p>Wymiana posiłków i&nbsp;produktów</p>
                    </Col>
                    <Col xs="6" md="3">
                        <img src={icon2} alt="" />
                        <p>Ponad 1600 dni treningowych</p>
                    </Col>
                    <Col xs="6" md="3">
                        <img src={icon3} alt="" />
                        <p>Opieka dietetyków online</p>
                    </Col>
                    <Col xs="6" md="3">
                        <img src={icon4} alt="" />
                        <p>Jadłospisy okolicznościowe</p>
                    </Col>
                </Row>
            </Container>
        </section>

        <section className="company4">
            <Container>
                <Row className="align-items-center">
                    <Col md="6">
                        <GatsbyImage image={data.app2.childImageSharp.gatsbyImageData} />
                    </Col>
                    <Col md="6">
                        <h2 className="text-center mt-4 mt-md-0">Wygodna aplikacja dla&nbsp;systemu Apple iOS oraz&nbsp;Google Android</h2>
                    </Col>
                </Row>
            </Container>
        </section>

        <section className="company5">
            <Container>
                <Row className="align-items-center">
                    <Col md="7">
                        <img src={balance} className='balance img-fluid' alt="" />
                        <h2><span className="color">Nowa funkcja w aplikacji:</span> Zanurz się w świecie relaksujących dźwięków</h2>
                    </Col>
                    <Col md="5">
                        <GatsbyImage image={data.headphones.childImageSharp.gatsbyImageData} />
                    </Col>
                </Row>
            </Container>
        </section>

        <section className="company6">
            <Container>
                <Row className="align-items-center">
                    
                    <Col md={{size: 6, offset: 6}}>
                        <img src={appIcon} alt="" className='app-icon' />
                        <h2>Dostęp do aplikacji w&nbsp;mniej, niż 2 minuty</h2>
                        <p>Otrzymasz od nas kody dostępowe, które możesz rozdać pracownikom. Pozwolą im one samodzielnie utworzyć konta z aktywowanym dostępem do Diet & Training by Ann.</p>
                    </Col>
                </Row>

                <GatsbyImage image={data.hand.childImageSharp.gatsbyImageData} className="d-md-none" />
            </Container>
        </section>

        <section className="company7">
            <Container>
                <h2 className="text-center">Już ponad <span className="bg">1 200 000</span> osób uzyskało efekty z&nbsp;aplikacją Diet & Training by Ann</h2>
            </Container>
        </section>

        <section className="company8">
            <Container>
                <h2 className="text-center">Trenerzy w aplikacji</h2>
                <Row className="align-items-center">
                    <Col md="4">
                        <div className="box">
                            <GatsbyImage image={data.ewelina.childImageSharp.gatsbyImageData} />
                        </div>
                        <div className="d-block d-md-none mb-5">
                            <h3>Ewelina Godlewska</h3>
                            <p>Treningi jogi oraz pilates dla kobiet.</p>
                        </div>
                    </Col>
                    <Col md="4">
                        <GatsbyImage image={data.ania.childImageSharp.gatsbyImageData} />
                        <div className="d-block d-md-none mt-4">
                            <h3>Ania Lewandowska</h3>
                            <p>Treningi wyszczuplające, siłowe, wytrzymałościowe dla kobiet oraz treningi dla par.</p>
                        </div>
                    </Col>
                    <Col md="4">
                        <div className="box">
                            <GatsbyImage image={data.kuba.childImageSharp.gatsbyImageData} />
                        </div>
                        <div className="d-block d-md-none">
                            <h3>Jakub Głąb</h3>
                            <p>Treningi siłowe, wytrzymałościowe, dla mężczyzn oraz treningi dla par.</p>
                        </div>
                    </Col>
                </Row>

                <Row className="d-none d-md-flex">
                    <Col md="4">
                        <h3>Ewelina Godlewska</h3>
                        <p>Treningi jogi oraz pilates dla kobiet.</p>
                    </Col>
                    <Col md="4">
                        <h3>Ania Lewandowska</h3>
                        <p>Treningi wyszczuplające, siłowe, wytrzymałościowe dla kobiet oraz treningi dla par.</p>
                    </Col>
                    <Col md="4">
                        <h3>Jakub Głąb</h3>
                        <p>Treningi siłowe, wytrzymałościowe, dla mężczyzn oraz treningi dla par.</p>
                    </Col>
                </Row>
            </Container>
        </section>

        <section className="company9">
            <Container>
                <h2>Zadbaj o swoich pracowników</h2>
                <p>Już od 20 abonamentów oferujemy atrakcyjne rabaty. Przy zakupie powyżej 500 dostepów możliwość przygotowania indywidualnego, obrandowanego landing&nbsp;page’a.</p>
            </Container>
        </section>

        <section className="company10" id="buy">
            <Container>
                <h2 className="text-center">Porozmawiajmy o potrzebach Twojego zespołu</h2>
                <Row className="align-items-center">
                    <Col md={{size: 3, offset: 3}}>
                        <img src={jarek} className="img-fluid rounded-circle" alt="" />
                    </Col>
                    <Col md="6" className="text-center text-md-left">
                        <h3>Jarosław Seredyński</h3>
                        <p>Account Manager</p>
                        <a href="mailto:jaroslaw.seredynski@dietlabs.pl ">jaroslaw.seredynski@dietlabs.pl </a><br/>
                        <a href="https://www.linkedin.com/in/jarosław-seredyński-03230117a/" target="_blank" rel="noopener noreferrer">https://www.linkedin.com/in/jarosław-seredyński-03230117a/</a>
                    </Col>
                </Row>
                
                
            </Container>
        </section>
    </Layout>
)};

export default Page;

export const query = graphql`{
  hero: file(relativePath: {eq: "company/backgroung_top.jpg"}) {
    ...fluidImage1920
  }
  app1: file(relativePath: {eq: "company/app1.png"}) {
    ...fluidImage960
  }
  app2: file(relativePath: {eq: "company/app2.png"}) {
    ...fluidImage960
  }
  headphones: file(relativePath: {eq: "company/headphones.png"}) {
    ...fluidImage960
  }
  hand: file(relativePath: {eq: "company/hand.jpg"}) {
    ...fluidImage960
  }
  kuba: file(relativePath: {eq: "company/screen_kuba.png"}) {
    ...fluidImage480
  }
  ania: file(relativePath: {eq: "company/screen_ania.png"}) {
    ...fluidImage480
  }
  ewelina: file(relativePath: {eq: "company/screen_ewelina.png"}) {
    ...fluidImage480
  }
  ogImage: file(relativePath: {eq: "company/ogimage.png"}) {
    childImageSharp {
      gatsbyImageData(width: 1200, placeholder: BLURRED, layout: FIXED)
    }
  }
}
`;
